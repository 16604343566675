<template>
	<div>
		<!-- <div class="stamp">
			<el-button @click="goHis" type="text">
				回退
			</el-button>
		</div> -->
		<el-descriptions class="margin-top" :column="3" border>
			<el-descriptions-item>
				<template slot="label">
					电梯注册代码
				</template>
				{{dioData.eleCode}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					电梯名称
				</template>
				{{dioData.eleName}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					救援识别码
				</template>
				{{dioData.rescueCode}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					物业单位
				</template>
				{{dioData.propertyUnitName}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					维保单位
				</template>
				{{dioData.maintainUnitName}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					电梯出厂编号
				</template>
				{{dioData.factoryNumber}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					计划时间
				</template>
				{{dioData.startTime}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					签到时间
				</template>
				{{dioData.signTime}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					维保结束时间
				</template>
				{{dioData.finishTime}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					维保时长
				</template>
				{{dioData.useTime}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					维保类型
				</template>
				{{dioData.maintainTypeName}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					签到经纬度
				</template>
				{{dioData.latitude}}&emsp;{{dioData.longitude}}
			</el-descriptions-item>
		</el-descriptions>
		<el-descriptions class="margin-top" :column="1" border>
			<el-descriptions-item>
				<template slot="label">
					维保人员
				</template>
				{{dioData.maintainUserNames}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					电梯安装位置
				</template>
				{{dioData.eleAddress}}
			</el-descriptions-item>
		</el-descriptions>

		<template v-if="dioData.wbImg.length>0">
			<h3 style="color: white;">维保签字:</h3>
			<div style="margin-top: 10px;">
				<template v-for="(itm, index) in dioData.wbImg">
					<el-image class="gdImg" :key="index" :src="itm" fit="scale-down" :preview-src-list="dioData.wbImg">
					</el-image>
				</template>
			</div>
		</template>

		<template v-if="dioData.wyImg.length>0">
			<h3 style="color: white;">物业签字:</h3>
			<div style="margin-top: 10px;">
				<template v-for="(itm, index) in dioData.wyImg">
					<el-image class="gdImg" :key="index" :src="itm" fit="scale-down" :preview-src-list="dioData.wyImg">
					</el-image>
				</template>
			</div>
		</template>

		<div class="tabDiv">
			<h3 style="color: white;">维保项:</h3>
			<template v-for="(item,index) in remarkList">
				<div class="remark-vessel">
					<span>{{index+1}}. {{item.item}}
						<el-button :type="item.status == 1 ? 'primary' : (item.status== 2 ? 'danger' : 'warning')"
							size="mini">{{item.statusName}}</el-button>
					</span>
					<p v-if="item.memo!=''">备注:{{item.memo}}</p>
					<div v-if="item.images.length>0" style="margin-top: 10px;">
						<template v-for="(itm, index) in item.images">
							<el-image class="gdImg" :key="index" :src="itm" fit="scale-down"
								:preview-src-list="item.images">
							</el-image>
						</template>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dioData: {},
				remarkList: [],
			}
		},
		methods: {
			goHis() {
				window.history.go(-1)
			},
		},
		created() {
			console.log(223, this.$route)

			let url1 = '/api/ele/web/maintainTask/getList'
			let url2 = '/api/ele/web/maintainTask/getInfo'
			this.$http
				.post(url1, {
					id: this.$route.params.id,
				})
				.then((res) => {
					if (res.data.success) {
						this.dioData = res.data.data.records[0]
						this.$http
							.post(url2, {
								id: this.$route.params.id,
							})
							.then((res) => {
								if (res.data.success) {
									this.dioData.rescueCode = res.data.data.elevatorInfo.rescueCode
									this.dioData.factoryNumber =
										res.data.data.elevatorInfo.factoryNumber
									this.dioData.longitude = res.data.data.longitude
									this.dioData.latitude = res.data.data.latitude

									let task = res.data.data.taskDetails
									let tasklst = ''
									for (var i = task.length - 1; i >= 0; i--) {
										if (task[i].status == 2) {
											tasklst = task[i].maintainRecord
											console.log('this.dioData.wbImg', JSON.parse(task[i].signImg))
											this.dioData.wbImg = JSON.parse(task[i].signImg)
											break
										} else if (task[i].status == 4) {
											console.log('this.dioData.wyImg', JSON.parse(task[i].signImg))
											this.dioData.wyImg = JSON.parse(task[i].signImg)
										}
									}
									if (tasklst != '') {
										this.remarkList = JSON.parse(tasklst)
										console.log('this.remarkList', this.remarkList)
										this.remarkList.forEach((qs) => {
											qs.statusName =
												qs.status == 1 ?
												'正常' :
												qs.status == 2 ?
												'异常' :
												'无此项'
										})
									}

									setTimeout(() => {
										window.print()
									}, 2000)
								} else {
									this.$message.error(res.data.msg)
								}
							})
					} else {
						this.$message.error(res.data.msg)
					}
				})
		},
	}
</script>

<style scoped>
	.stamp {
		width: 98%;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.gdImg {
		width: 80px;
		height: 80px;
		border: 1px solid #00f6ff;
		margin-right: 20px;
	}

	.remark-vessel {
		color: white;
		margin-top: 10px;
	}

	.tabDiv {
		padding: 10px;
	}
</style>
